/* @font-face {
  font-family: "Phase";
  src: url("Phase-AGX.woff2") format("woff2");
} 
*/
@font-face {
  font-family: "phase-aregular";
  src: url("./Phase-AGX.ttf");
  font-weight: normal;
  font-style: normal;
}
* {
  box-sizing: border-box;
  scrollbar-width: none;
}
*::-moz-selection {
  background: #fde68a;
}

*::selection {
  background: #fde68a;
}
*::-webkit-scrollbar {
  display: none;
}
body {
  margin: 0;
  font-family: "phase-aregular", sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  color: #111827;
  background-color: #f1f5f9;
  /* font-size: 16px; */
  /* font-variation-settings: "phon" 20, "phtw" 20, "phth" 20;
  transition: font-variation-settings 1s; */
}

a {
  color: #3b82f6;
  text-decoration: none;
}
.dataprivacy p {
  margin: 12px 0px;
}
.wrapper {
  position: relative;
  height: calc(100% - 24px);
  width: calc(100% - 24px);
  margin: 12px;
  overflow: hidden;
}
.wrapper iframe {
  padding: 12px;
  transform: scale(2);
  border: none;
}
.player {
  /* padding: 12px; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}
.player video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.button {
  display: inline-block;
  color: #1e293b;
  border-bottom: 1px solid #1e293b;
  margin: 2px;
  padding: 2px 2px;
  line-height: 9px;
  cursor: pointer;
  user-select: none;
}

@media (hover: hover) and (pointer: fine) {
  .button:hover {
    color: #1e293b;
    border-bottom: 1px solid #1e293b;
    animation: button 0.16s steps(1) infinite;
    -webkit-animation: button 0.16s steps(1) infinite;
  }
}
.buttonflicker {
  animation: button 0.16s steps(1) infinite;
  -webkit-animation: button 0.16s steps(1) infinite;
}
.pdf-wrapper {
  /* display: flex;
  justify-content: center; */
  height: 100%;
  width: 100%;
}
.pdf-wrapper figure {
  /* .pdf-wrapper div .pdf-wrapper .iiz__img  */
  /* height: 100%;
  width: 100%; */
  height: 100%;
  width: 100%;
}
.pdfzoom {
  width: 24px;
  height: 24px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  position: absolute;
  top: 0;
  z-index: 3;
}
.tools {
  display: flex;
}
.react-transform-wrapper,
.react-transform-component {
  width: 100% !important;
  height: 100% !important;
}
.pdfzoom.in {
  background-color: rgb(34, 197, 94);
  left: 0px;
}
.pdfzoom.out {
  background-color: rgb(239, 68, 68);
  left: 24px;
}
@keyframes button {
  50% {
    color: #505866;
  }
}
@-webkit-keyframes button {
  50% {
    color: #505866;
  }
}
.buttonwhite {
  color: white;
  user-select: none;
}

@media (hover: hover) and (pointer: fine) {
  .buttonwhite:hover {
    color: white;
    animation: buttonwhite 0.16s steps(1) infinite;
    -webkit-animation: buttonwhite 0.16s steps(1) infinite;
  }
}

.nomatch {
  color: #adb7c5;
  animation: buttonwhite 0.4s steps(1) infinite;
  -webkit-animation: buttonwhite 0.4s steps(1) infinite;
}
@keyframes buttonwhite {
  50% {
    color: #d6dae0;
  }
}
@-webkit-keyframes buttonwhite {
  50% {
    color: #d6dae0;
  }
}
.nomatch > div > div {
  animation: nomatch 4s ease-in-out infinite;
  animation-direction: alternate;
}
@keyframes nomatch {
  from {
    transform: rotatey(-30deg);
    font-variation-settings: "phon" 100, "phtw" 100, "phth" 0;
  }
  to {
    transform: rotatey(30deg);
    font-variation-settings: "phon" 0, "phtw" 0, "phth" 100;
  }
}
@media (hover: hover) and (pointer: fine) {
  .buttonhead:hover,
  .smalltext:hover {
    color: #15191f !important;
  }
}
.textrrrr {
  background-color: white;
  padding: 4px;
}
.textrrrr div {
  height: 100%;
}
.textrrrr p {
  margin: 0;
  display: inline;
  hyphens: auto;
  text-align: justify;
}
.textrrrr h1,
.textrrrr h2,
.textrrrr h3,
.textrrrr h4,
.textrrrr h5 {
  font-size: inherit;
  font-weight: bold;
  margin: 0;
  display: inline-block;
}

.buttonopen {
  transition: font-variation-settings 0.6s ease;
}
.buttonopen:hover {
  font-variation-settings: "phon" 0, "phtw" 0, "phth" 100;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
hr {
  border: none;
  margin: 0;
  border-top: 1px solid #a3a3a3;
}
.list {
  position: relative;
  width: 100%;
  height: 100%;
}

.list > div {
  position: absolute;
  will-change: transform, width, height;
}
.list > div > div > div > img {
  padding: 12px;
  overflow: hidden;
  background-clip: content-box;
  background-color: white;
}
.list > div > div > img {
  padding: 4px;
  overflow: hidden;
}
.pdf-wrapper {
  height: 100%;
  width: 100%;
  overflow-x: scroll;
  overflow-y: scroll;
}
.pdf-wrapper figure {
  height: 100%;
}
.pdf-wrapper-masonry {
  height: 100%;
  overflow: hidden;
  margin: 12px;
}
.pdf-wrapper canvas,
.pdf-wrapper-masonry canvas,
.pdf-wrapper-masonry .react-pdf__Page__svg,
.pdf-wrapper-masonry .react-pdf__Page__svg svg {
  width: 100% !important;
  height: 100% !important;
}
.grabber:hover {
  cursor: grab;
}
.grabber:active:hover {
  cursor: grabbing;
}
.supershadow {
  box-shadow: 0px 0px 3.6px rgba(0, 0, 0, 0.024),
    0px 0px 10px rgba(0, 0, 0, 0.035), 0px 0px 24.1px rgba(0, 0, 0, 0.046),
    0px 0px 80px rgba(0, 0, 0, 0.07);
}
.contactshadow {
  box-shadow: 0px 0px 0.6px rgba(0, 0, 0, 0.035),
    0px 0px 5px rgba(0, 0, 0, 0.07);
}
.intro {
  display: inline-block;
  animation-delay: 1s;
  animation: loading 16s infinite ease-in-out;
  -webkit-animation: loading 5s infinite ease-in-out;
}
@media only screen and (max-width: 600px) {
  .videomobile {
    padding-bottom: 52px;
  }
  .zoomcontrols {
    display: grid;
  }
}
/* @keyframes blink {
  0% {
    font-variation-settings: "phon" 100, "phtw" 20, "phth" 0;
  }
  50 {
    font-variation-settings: "phon" 0, "phtw" 0, "phth" 100;
  }
} */
.contact {
  font-size: max(16px, 1.6vw);
  text-align: center;
}
.contact strong {
  font-weight: inherit;
  animation: loading 10s infinite ease-in-out;
  margin: 46px 0px;
  display: inline-block;
  font-size: 6vw;
}
.playercontent-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}
.opacity-transition {
  transition: opacity 0.3s;
}

.react-playercontent {
  position: absolute;
  top: 0;
  left: 0;
}
@keyframes loading {
  0% {
    font-variation-settings: "phon" 100, "phtw" 20, "phth" 0;
  }
  20% {
    font-variation-settings: "phon" 0, "phtw" 0, "phth" 100;
  }
  40% {
    font-variation-settings: "phon" 100, "phtw" 0, "phth" 100;
  }
  60% {
    font-variation-settings: "phon" 0, "phtw" 100, "phth" 0;
  }
  80% {
    font-variation-settings: "phon" 100, "phtw" 0, "phth" 0;
  }
  100% {
    font-variation-settings: "phon" 100, "phtw" 20, "phth" 0;
  }
}

.v1 {
  font-variation-settings: "phon" 20, "phtw" 20, "phth" 20;
}
.v2 {
  font-variation-settings: "phon" 90, "phtw" 20, "phth" 0;
}
.v3 {
  font-variation-settings: "phon" 0, "phtw" 0, "phth" 20;
}
.v4 {
  font-variation-settings: "phon" 70, "phtw" 0, "phth" 0;
}
.v5 {
  font-variation-settings: "phon" 70, "phtw" 0, "phth" 0;
}
.v6 {
  font-variation-settings: "phon" 0, "phtw" 30, "phth" 80;
}
.v7 {
  font-variation-settings: "phon" 0, "phtw" 0, "phth" 50;
}
.v8 {
  font-variation-settings: "phon" 70, "phtw" 0, "phth" 10;
}
.v9 {
  font-variation-settings: "phon" 10, "phtw" 70, "phth" 10;
}
.v7 {
  font-variation-settings: "phon" 0, "phtw" 40, "phth" 0;
}
.v8 {
  font-variation-settings: "phon" 0, "phtw" 40, "phth" 0;
}
.v9 {
  font-variation-settings: "phon" 90, "phtw" 40, "phth" 10;
}
.v10 {
  font-variation-settings: "phon" 20, "phtw" 20, "phth" 20;
}

#webpack-dev-server-client-overlay {
  display: none;
}
.at {
  display: inline-block;
  animation: atspin 5s linear infinite;
}
@keyframes atspin {
  0% {
    transform: rotateY(0deg);
  }
  20% {
    transform: rotateY(360deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
/* .contact p {
  margin: 0;
}
p {
  margin: 0;
} */
.loading {
  animation: 0.4s linear infinite placeHolderShimmer;
}
@keyframes placeHolderShimmer {
  0% {
    background-color: #fff;
  }
  50% {
    background-color: #eee;
  }
  100% {
    background-color: #fff;
  }
}
